<template>
  <div class="container_m_s">
    <div class="earnings_clone" style="background:#fff" @click="cloneBtn">
      <img style="width:15px;height:15px" src="../../assets/leftBtn.png" />
      <div>返回</div>
    </div>
    <div class="header_top_ms">
      <div class="content_m_s">
        <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished">

          <div class="content_item_m" v-for="(item,index) in dataList" :key="index">
            <div style="display:flex;justify-content: space-between">
              <div>申请时间：{{item.create_time}}</div>
              <div :style="item.status_num==1?'color:rgba(0, 56, 255, 1)':(item.status_num==2?'color:rgba(78, 184, 76, 1)':'color:rgba(26, 26, 26, 0.50)')">{{item.status_text}}</div>
            </div>
            <div>物料数量：{{item.audit_info?item.audit_info.length:0}}</div>
            <div class="icons">
              <div v-if="item.pay_state==3" class="error_text" style="color:red">异常：{{item.pay_remark.remark}}</div>
              <div v-else>
                <div v-if="item.status_num==3" class="error_text" style="color:red">异常：{{item.close_state==0?(item.audit_remark.remark||item.audit_remark.remark):item.close_remark.remark}}</div>
                <div v-else>
                  <div v-if="item.audit_state==1" style="color:rgba(79, 183, 76, 1)">当前流程：回寄合同审核</div>
                  <div v-if="item.audit_state==2" style="color:rgba(79, 183, 76, 1)">当前流程：用户待支付</div>
                  <div v-if="item.audit_state==3" style="color:rgba(79, 183, 76, 1)">当前流程：财务审核</div>
                  <div v-if="item.audit_state==4" style="color:rgba(79, 183, 76, 1)">当前流程：待生成合同</div>
                  <div v-if="item.audit_state==5" style="color:rgba(79, 183, 76, 1)">当前流程：物料寄回</div>
                  <div v-if="item.audit_state==6" style="color:rgba(79, 183, 76, 1)">当前流程：已完成</div>
                </div>
              </div>

              <div @click="detailsBtn(item.audit_id)">查看详情 ></div>
            </div>
          </div>

        </vue-loadmore>
      </div>
    </div>
    <div v-if="dataList.length==0" class="list_text">暂无数据</div>
  </div>
</template>
<script>
import {
  getMemberSList_api,
  getOnleMemberSList_api,
  sharesChange_list_api
} from "@/api/admin";
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      headerBtnShow: true,
      queryList: {
        page: 1,
        limit: 10,
        shipments_state: 1
      },
      dataList: [],
      total: 0,
      finished: false
    };
  },
  components: {},
  async created() {
    document.title = "申请记录";
    // 判断用户
    this.getList();
    // this.getToken();
    // this.getCode()
    // 1股东报名2股东报名3会员报名4工分增值5股份受让6股份转让7会员续签8合约续签9股份还款
  },
  methods: {
    detailsBtn(id) {
      this.$router.push(`./nextAudit?id=${id}`);
    },
    cloneBtn() {
      console.log(123);
      this.$router.go(-1);
    },
    location(row) {
      if (row.union_type == 3) {
        console.log(row.data.express_info);
        this.$router.push(
          `./shipmentsList?data=${encodeURIComponent(
            JSON.stringify(row.data.express_info)
          )}`
        );
      } else {
        this.$router.push(
          `./shipmentsList?address==${row.audit_shipments.receiver_address}&data=${encodeURIComponent(
            JSON.stringify(row.shipments.shipments_info)
          )}`
        );
      }
    },
    deliver(row) {
      Dialog.confirm({
        title: "提示",
        message: "确定收货"
      })
        .then(() => {
          this.deleteOrder(row);
        })
        .catch(() => {
          console.log(1);
        });
    },
    deleteOrder(row) {
      let data = {};
      console.log(row);
      if (row.union_type == 3) {
        data = {
          express_state: 3
        };
        getMemberSList_api(data, row.audit_id).then(res => {
          console.log(res);
          if (res.code == 0) {
            Toast({
              message: "操作成功",
              icon: "success",
              duration: 1000
            });
            this.dataList = [];
            this.getList();
          }
        });
      } else {
         data = {
          express_state: 3
        };
        getOnleMemberSList_api(data, row.shipments.shipments_id).then(res => {
          console.log(res);
          if (res.code == 0) {
            Toast({
              message: "操作成功",
              icon: "success",
              duration: 1000
            });
            this.dataList = [];
            this.getList();
          }
        });
      }
    },
    // 上拉加载------------------
    onRefresh(done) {
      console.log(done, "13213");
      this.queryList.page == 1;
      this.finished = false;
      this.fetch();
      done();
    },
    onLoad(done) {
      // if (this.queryList.page <= 10) {
      this.queryList.page++;
      this.fetch();
      // } else {
      // all data loaded
      this.finished = true;
      // }
      done();
    },

    fetch() {
      this.getList();
    },
    getList() {
      sharesChange_list_api(this.queryList).then(res => {
        if (res.code == 0) {
          console.log(res);

          if (this.headerBtnShow) {
            this.total = res.pagination.total;
          }
          res.data.forEach(e => {
            if (e.close_state == 0) {
              if (e.audit_state < 6) {
                e.status_text = "进行中";
                e.status_num = 1;
              } else {
                e.status_text = "已完成";
                e.status_num = 2;
              }
            } else {
              e.status_text = "已关闭";
              e.status_num = 3;
            }
            switch (e.union_type) {
              case 1:
                e.union_text = "股东报名";
                e.union_text_s = "销售合约,工分合约";
                break;
              case 2:
                e.union_text = "股东报名";
                e.union_text_s = "销售合约,工分合约";
                break;
              case 3:
                e.union_text = "会员报名";
                e.union_text_s = "会员证，申请书";
                break;
              case 4:
                e.union_text = "工分增值";
                if (e.data.shares_amount == 0) {
                  e.union_text_s = "工分合约，居间协议，申请书";
                } else {
                  e.union_text_s = "居间协议,申请书";
                }

                break;
              case 5:
                e.union_text = "股份受让";
                e.union_text_s = "工分合约，居间协议，申请书";
                break;
              case 6:
                e.union_text = "股份转让";
                e.union_text_s = "会员证,销售合约";
                break;
              case 7:
                e.union_text = "会员续签";
                e.union_text_s = "工分合约，居间协议，申请书";
                break;
              case 8:
                e.union_text = "合约续签";
                e.union_text_s = "工分合约，居间协议，申请书";
                break;
              case 10:
                e.union_text = "物料变更";
                e.union_text_s = "物销售合约、工分合约";
                break;
              default:
                e.union_text = "股份还款";
            }
          });
          this.dataList = this.dataList.concat(res.data);
        }
      });
    },
    selectBtn() {
      this.headerBtnShow = !this.headerBtnShow;
      this.dataList = [];
      this.queryList.page = 1;
      this.queryList.shipments_state = this.headerBtnShow ? 1 : 0;
      this.getList();
    }
  }
};
</script>
<style>
.header_top_ms {
  margin-top: 10px;
}
.error_text{
  width:400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.header_top_ms > div {
  flex: 1;
  font-size: 30px;
  position: relative;
}
.container_m_s {
  background-color: #f4f4f4;
}
.header_item {
  width: 100px;
  margin: 0 auto;
  padding-bottom: 10px;
}
.content_item_m {
  width: 700px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(220, 220, 220, 0.1);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  margin: 20px 25px 0;
  padding: 35px 39px;
  box-sizing: border-box;
  font-size: 24px;
  position: relative;
}
.content_item_m > div {
  margin-bottom: 20px;
}
.right_btn {
  position: absolute;
  top: 40px;
  right: 20px;
  font-size: 26px;
}
.content_item_m img {
  width: 38px;
  height: 44px;
}
.right_btn .deliver {
  width: 120px;
  height: 46px;
  background: #1d8cfe;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  color: #fff;
  text-align: center;
  line-height: 46px;
  font-size: 20px;
  margin-top: 26px;
}
.content_title {
  color: #000;
  font-size: 32px;
  margin-bottom: 10px;
}
.content_info .text {
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.4);
}
.bar {
  position: absolute;
  top: 14px;
  left: 220px;
  width: 30px;
  height: 30px;
  background: #fe3e4d;
  opacity: 1;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #fff;
  font-size: 16px;
}
.list_text {
  text-align: center;
  margin-top: 40px;
  font-size: 40px;
}
.earnings_clone {
  padding-left: 31px;
  color: #000000;
  font-size: 15px;
  font-weight: 800;
  padding-top: 10px;
  padding-bottom: 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 9999;
}
.icons {
  display: flex;
  justify-content: space-between;
}
</style>